import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { icons } from './svg-icon/icon';
@NgModule({
  imports: [CommonModule],
  providers: [
    provideSvgIcons(Object.values(icons)),
  ],
})
export class SvgIconModule {}
