/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable, inject } from '@angular/core';
import { PostCategory } from '../models/post-category.model';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class PostCategoryService {

  private readonly afs = inject(AngularFirestore);
  private readonly nzMesageSV = inject(NzMessageService);

  getPostDetail(post_category_id: string) {
    return this.afs.doc<PostCategory>(`post_category/${post_category_id}`).valueChanges({ idField: 'id' });
  }

  async listPostCategory(limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string) {
    try {
      let query: Query = this.afs.firestore.collection('post_category');
      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }
      query = query.orderBy('created_at', 'desc');
      if (start_after) {
        const doc = await this.afs.firestore.doc(`post_category/${start_after}`).get();
        query = query.startAfter(doc).limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`post_category/${end_before}`).get();
        query = query.endBefore(doc).limitToLast(limit);
      } else {
        query = query.limit(limit);
      }
      return query.get().then(querySnapshot => {
        const list: Array<PostCategory> = [];
        querySnapshot.forEach((doc: any) => {
          const data = doc.data() as PostCategory;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      }).catch(error => {
        console.log(error);
        return [];
      });
    } catch (error) {
      console.log("ERROR-list-post-category", error);
      return [];
    }
  }

  addPostCategory(post_category: PostCategory) {
    return this.afs.collection(`post_category`).add({ ...post_category }).then((rs: any) => {
      this.nzMesageSV.success('Post category successfully added!');
      return ({ flag: true, message: 'Post category successfully added!', data: { ...post_category, id: rs.id } });
    }).catch((error: any) => {
      this.nzMesageSV.success(error.message);
      return ({ flag: false, message: error.message, data: {} as PostCategory });
    });
  }

  updatePost(post_category: PostCategory) {
    delete post_category.doc;
    return this.afs.doc(`post_category/${post_category.id}`).update({ ...post_category }).then(() => {
      this.nzMesageSV.success('Post category successfully updated!');
      return ({ flag: true, message: 'Post category successfully updated!' });
    }).catch((error: any) => {
      this.nzMesageSV.success(error.message);
      return ({ flag: false, message: error.message });
    });
  }

  deletePost(post_category_id: string) {
    return this.afs.doc(`post_category/${post_category_id}`).delete().then(() => {
      this.nzMesageSV.success('Post category successfully deleted!');
      return ({ flag: true, message: 'Post category successfully deleted!' });
    }).catch((error: any) => {
      this.nzMesageSV.success(error.message);
      return ({ flag: false, message: error.message });
    });
  }

  async existsPostCategorybySlug(slug: string) {
    const post = await this.afs.firestore.collection('post_category').where('slug', '==', slug).get();
    if (post.size > 0) return true;
    else return false;
  }
}
