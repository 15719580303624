import { Injectable, inject } from '@angular/core';
import { AngularFirestore, DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Investment } from '../models/investment.model';
import { Observable, of } from 'rxjs';
import * as firebase from 'firebase/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';
@Injectable({
  providedIn: 'root'
})
export class InvestmentService {
  private readonly afs = inject(AngularFirestore)
  private readonly nzMesageSV = inject(NzMessageService);
  
  async listInvestment(
    limit = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string,
    order_by?: string,
    order_desc = true) {
    try {
      let query: Query = this.afs.firestore.collection('investment');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      if (order_by) {
        query = query.orderBy(order_by, order_desc ? 'desc' : 'asc');
      } else {
        query = query.orderBy('created_at', order_desc ? 'desc' : 'asc');
      }

      if (start_after) {
        const doc = await this.afs.firestore.doc(`investment/${start_after}`).get();
        query = query.startAfter(doc);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`investment/${end_before}`).get();
        query = query.endBefore(doc);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query.get().then((querySnapshot: any) => {
        const list: Array<Investment> = [];
        querySnapshot.forEach((doc: any) => {
          const data = doc.data() as Investment;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  listInvestmentRealTime(
    limit = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>,
    order_by?: string,
    order_desc = true) {
    try {
      let query: Query = this.afs.firestore.collection('investment');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      if (order_by) {
        query = query.orderBy(order_by, order_desc ? 'desc' : 'asc');
      } else {
        query = query.orderBy('created_at', order_desc ? 'desc' : 'asc');
      }

      if (start_after) {
        query = query.startAfter(start_after);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        query = query.endBefore(end_before);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return new Observable<Investment[]>(observ => {
        query.onSnapshot((querySnapshot: any) => {
          const list: Array<Investment> = [];
          querySnapshot.forEach((doc: any) => {
            const data = doc.data() as Investment;
            data.id = doc.id;
            data.doc = doc;
            list.push(data);
          });
          return observ.next(list);
        })
      });
    } catch (error) {
      console.log(error);
      return of([]);
    }
  }

  getInvestmentDetail(investment_id: string) {
    return this.afs.firestore.doc(`investment/${investment_id}`).get().then(res => ({ id: res.id, ...res.data() } as Investment))
  }

  addInvestment(investment: Investment) {
    return this.afs.collection(`investment`).add({ ...investment, created_at: firebase.Timestamp.now() }).then(rs => {
      this.nzMesageSV.success('Investment successfully added!');
      return ({ flag: true, message: 'investment successfully added!', data: { ...investment, id: rs.id } });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message, data: {} as Investment });
    });
  }

  updateInvestment(investment: Investment) {
    return this.afs.doc(`investment/${investment.id}`).update({ ...investment, updated_at: firebase.Timestamp.now() }).then(() => {
      this.nzMesageSV.success('Investment successfully updated!');
      return ({ flag: true, message: 'Investment successfully updated!' });
    }).catch((err: any) => {
      console.log(err);
      return ({ flag: false, message: err.message });
    });
  }

  deleteInvestment(investment_id: string) {
    return this.afs.doc(`investment/${investment_id}`).delete().then(() => {
      this.nzMesageSV.success('Investment successfully deleted!');
      return ({ flag: true, message: 'Investment successfully deleted!' });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message });
    });
  }
  async existsInvestmentBySlug(slug: string) {
    const location = await this.afs.firestore.collection('investment').where('slug', '==', slug).limit(1).get();
    if (location.size > 0) return true;
    else return false;
  }
}
