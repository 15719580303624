/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from '@franbase/shared/data-access';
@Injectable({
providedIn: 'root'
})
export class UserService {
    afs = inject(AngularFirestore);
    async getUserDetail(uid: string) {
        try {
          const doc_user = await this.afs.firestore.collection("user").doc(uid).get();
          if (doc_user.exists) {
            // console.log(doc_user.data());
            const data_user = doc_user.data() as User;
            data_user.uid = doc_user.id;
            return data_user;
          } else {
            return null;
          }
        } catch (error) {
          return null;
        }
    }
}