import { Injectable, inject } from '@angular/core';
import { signInWithEmailAndPassword, Auth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    router = inject(Router);
    private afAuth = inject(Auth);
    private route = inject(Router);

    async loginWithEmail(email: string, password: string) {
      try {
        const rs = await signInWithEmailAndPassword(this.afAuth, email, password);
        console.log(rs);

        this.route.navigate(["/"]);
        return { "success": true, "message": "Login success." }
      } catch (error: any) {
        return { "success": false, "message": error.message }
      }
    }

    async logOut() {
      const rs = await this.afAuth.signOut();
      this.router.navigate([`/login`]);
    }
}
