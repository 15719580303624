import { DocumentData, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
export class Franchise {
  id?: string;
  objectID?:string;
  doc?: QueryDocumentSnapshot<DocumentData>;
  created_at?: any;
  updated_at?: any;
  franchise_name?: string;
  slug?: string;
  description!: string;
  keyword!: string;
  franchise_image?: string;
  business_type_id?: string;
  industry_id!: string[];
  location_id!: string[];
  investment_id!: string[];
  logo_image?:string;
  cover_image?: string;

  current_ceo_id?: string;
  current_ceo?: string;
  founded_by_id?: string;
  founded_by?: string;
  founded_year?: number;

  hq_address?: string;
  hd_city?: string;
  hd_state?: string;
  hd_lat?: number;
  hd_long?: number;
  hd_postal_code?: string;

  initial_franchise_fee?: number;
  investment_range_from?: number;
  investment_range_to?: number;
  minumum_cash_required?: number;
  net_worth_required?: number;
  royalties?: string;
  total_location?: number;
  website_url?: string;
  fdd_file?: string;
  note?: string;
  updated_by?: string;

  // overview tab
  short_description!: string;
  faq!: Array<{heading: string, content: string}>;
  //about tab
  about!: string;
  //financials tab
  financials_description!: string;
  financials_heading!: string;
  financials_subheading!: string;
  financials_table!: Array<{expenditure: string, amount_low: number, amount_hight: number}>;

  log_type = 'franchise';
}
