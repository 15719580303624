import { DocumentData, DocumentReference, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
export class Industry {
  id?: string;
  objectID?: string;
  doc?: QueryDocumentSnapshot<DocumentData>;
  created_at?: any;
  updated_at?: any;
  title!: string;
  slug?: string;
  keyword!: string;
  description!: string;
  parent_id?: string;
  featured?: boolean;
  hide?: boolean;
  hero_heading?: string;
  hero_subheading?: string;
  hero_graphic?: DocumentReference;
  hero_right_content?: string;
  faq?: Array<{heading: string; content: string}>;
  banner_fact!: string;
  featured_franchise!: string[];
  opportunity_franchise!: string[];
  trending_franchise!: string[];
  featured_image!: string;
  icon!: string;
  log_type = 'industry';
}
