import { DocumentData, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';

export class Media {
    id!: string;
    doc?: QueryDocumentSnapshot<DocumentData>;
    name?: string;
    avatar?: string;
    created_at?: Timestamp;
    extension?: string;
    medium?: string;
    original?: string;
    thumb?: string;
    type?: string;
    public?: boolean;
    size?: number;
    owner_id!: string;
    log_type = "media";
}