import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { AuthService } from '@franbase/shared/data-access';

@Component({
  selector: 'franbase-user-dropdown',
  standalone: true,
  imports: [CommonModule, NzDropDownModule, NzAvatarModule],
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDropdownComponent {

  authSV = inject(AuthService);

  logOut() {
    this.authSV.logOut();
  }
}
