<ng-container *ngIf="true">
  <div nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
    <div class="flex items-center group space-x-1.5 text-gray-500 hover:text-primary-500 cursor-pointer">
      <nz-avatar nzIcon="user" nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"></nz-avatar>
    </div>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu class="min-w-[120px] rounded-md">
      <li nz-menu-item (click)="logOut()">Log Out</li>
    </ul>
  </nz-dropdown-menu>
</ng-container>
