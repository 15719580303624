import { Injectable, inject } from '@angular/core';
import { Investment, InvestmentService } from '@franbase/shared/data-access';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { EMPTY, Observable, catchError, combineLatest, distinctUntilChanged, map, mergeMap, of, switchMap, tap } from 'rxjs';

interface InvestmentState extends EntityState<any> {
  loading: boolean,
  error: string
}
const adapter = createEntityAdapter<any>();
const initialState: InvestmentState = adapter.getInitialState({
  loading: false,
  error: ''
})

@Injectable({
  providedIn: 'root'
})
export class InvestmentStoreService extends ComponentStore<InvestmentState> {

  InvestmentSV = inject(InvestmentService);

  constructor() { super(initialState); }

  data$ = this.select(s=> Object.values(s.entities) as Investment[]);
  loading$ = this.select((s) => s.loading);
  error$ = this.select((s) => s.error);

  loadInvestment$ = this.effect(none$ => {
    return none$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap(() => {
        return this.InvestmentSV.listInvestment(-1, [], undefined, undefined, 'title', true);
      }),
      map((final_list: Investment[]) => {
        if (final_list && final_list.length > 0) {
          this.setState((state) => adapter.setAll(final_list, state));
          this.patchState({ loading: false });
        }

        if (final_list.length == 0) {
          this.patchState({ loading: false });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  createInvestment$ = this.effect((investment$: Observable<Investment>) => {
    return investment$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((investment: Investment) => {
        return this.InvestmentSV.addInvestment(investment);
      }),
      map(rs => {
        if (rs.flag) {
          this.setState((state) => adapter.setOne(rs.data, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  updateInvestment$ = this.effect((investment$: Observable<Investment>) => {
    return investment$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((investment: Investment) => {
        return combineLatest(this.InvestmentSV.updateInvestment(investment), of(investment));
      }),
      map(([rs, investment]) => {
        if (rs.flag) {
          this.setState((state) => adapter.updateOne({id: investment.id!, changes: investment}, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  deleteInvestment$ = this.effect((investment_id$: Observable<string>) => {
    return investment_id$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((investment_id: string) => {
        return combineLatest(this.InvestmentSV.deleteInvestment(investment_id), of(investment_id));
      }),
      map(([rs, investment_id]) => {
        if (rs.flag) {
          this.setState((state) => adapter.removeOne(investment_id, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  getOneInvestment$ = this.effect((investment_id$: Observable<string>) => {
    return investment_id$.pipe(
      distinctUntilChanged(),
      mergeMap((investment_id) => {
        return this.InvestmentSV.getInvestmentDetail(investment_id).then(
          rs => {
            this.setState((state) => adapter.setOne(rs, state));
          }
        )
      })
    )
  })
  getOneInvestment(investment_id: string) {
    return this.select((s) => s.entities[investment_id] as Investment);
  }

  async existsInvestmentBySlug(slug: string){
    return this.InvestmentSV.existsInvestmentBySlug(slug);
  }

}
