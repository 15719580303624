import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '@franbase/backend/shared/ui/layout';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['/franchise']);

const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('@franbase/shared/ui/login').then(mod => mod.SharedUiLoginComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToDashboard }
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: '',
        redirectTo: 'franchise',
        pathMatch: 'full',
      },
      {
        path: 'franchise',
        loadChildren: () => import('@franbase/backend/franchise/features').then(mod => mod.BackendFranchiseFeaturesModule),
      },
      // {
      //   path: 'business-type',
      //   loadChildren: () => import('@franbase/backend/business-type/features').then(mod => mod.BackendBusinessTypeFeaturesModule),
      // },
      {
        path: 'industry',
        loadChildren: () => import('@franbase/backend/industry/features').then(mod => mod.BackendIndustryFeaturesModule),
      },
      {
        path: 'location',
        loadChildren: () => import('@franbase/backend/location/features').then(mod => mod.BackendLocationFeaturesModule),
      },
      {
        path: 'investment',
        loadChildren: () => import('@franbase/backend/investment/features').then(mod => mod.BackendInvestmentFeaturesModule),
      },
      // {
      //   path: 'person',
      //   loadChildren: () => import('@franbase/backend/person/features').then(mod => mod.BackendPersonFeaturesModule),
      // },
      {
        path: 'post',
        loadChildren: () => import('@franbase/backend/post/features').then(mod => mod.BackendPostFeaturesModule),
      },
      {
        path: 'post-category',
        loadChildren: () => import('@franbase/backend/post-category/features').then(mod => mod.BackendPostCategoryFeaturesModule),
      },
      {
        path: 'media',
        loadChildren: () => import('@franbase/backend/media/features').then(mod => mod.BackendMediaFeaturesModule),
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class BackendShellRoutingModule { }
