/* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Params } from '@angular/router';
import { environment } from '@franbase/shared/environments';
import { Observable, catchError, map, of, retry, switchMap, throttleTime, throwError } from 'rxjs';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Franchise } from '../models/franchise.model';
import { AuthStore } from '@franbase/shared/auth/data-access';
import * as firebase from 'firebase/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class FranchiseService {
  private readonly http = inject(HttpClient);
  private readonly afs = inject(AngularFirestore);
  private readonly authStore = inject(AuthStore);
  private readonly nzMesageSV = inject(NzMessageService);

  idToken$ = this.authStore.idToken$;

  getListFranchiseAlgolia(filter_obj: Params) {
    return this.authStore.idToken$.pipe(switchMap(idToken => {
      if (idToken) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          })
        }
        console.log(`${environment.api_algolia}/filter-franchise`);
        return this.http.post(`${environment.api_algolia}/filter-franchise`, filter_obj, httpOptions)
        .pipe(
          retry(3), // retry a failed request up to 3 times
          catchError(err=>{
            console.log(err);
            return throwError(() => new Error('Something bad happened; please try again later.'));
          })
        );
      } else {
        return of(null);
      }
    }));
  }

  getPublicListFranchiseAlgolia(filter_obj: Params) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    return this.http.post(`${environment.api_algolia}/public-filter-franchise`, filter_obj, httpOptions);
  }

  async listFranchise(
    limit = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string,
    order_by?: string,
    order_desc = true) {
    try {
      let query: Query = this.afs.firestore.collection('franchise');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      if (order_by) {
        query = query.orderBy(order_by, order_desc ? 'desc' : 'asc');
      } else {
        query = query.orderBy('created_at', order_desc ? 'desc' : 'asc');
      }

      if (start_after) {
        const doc = await this.afs.firestore.doc(`franchise/${start_after}`).get();
        query = query.startAfter(doc);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`franchise/${end_before}`).get();
        query = query.endBefore(doc);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query.get().then((querySnapshot: any) => {
        const list: Array<Franchise> = [];
        querySnapshot.forEach((doc: any) => {
          const data = doc.data() as Franchise;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  listFranchiseRealTime(
    limit = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>,
    order_by?: string,
    order_desc = true) {
    try {
      let query: Query = this.afs.firestore.collection('franchise');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      if (order_by) {
        query = query.orderBy(order_by, order_desc ? 'desc' : 'asc');
      } else {
        query = query.orderBy('created_at', order_desc ? 'desc' : 'asc');
      }

      if (start_after) {
        query = query.startAfter(start_after);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        query = query.endBefore(end_before);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return new Observable<Franchise[]>(observ => {
        query.onSnapshot((querySnapshot: any) => {
          const list: Array<Franchise> = [];
          querySnapshot.forEach((doc: any) => {
            const data = doc.data() as Franchise;
            data.id = doc.id;
            data.doc = doc;
            list.push(data);
          });
          return observ.next(list);
        })
      });
    } catch (error) {
      console.log(error);
      return of([]);
    }
  }

  getFranchiseDetail(franchise_id: string) {
    return this.afs.firestore.doc(`franchise/${franchise_id}`).get().then(res => ({ id: res.id, ...res.data() } as Franchise));
  }

  getFranchiseDetailbySlug(franchise_slug: string) {
    return this.afs.collection('franchise', ref => {
      return ref.where('slug', '==', franchise_slug)
    }).snapshotChanges().pipe(map(actions => {
      return actions.map(rs => {
        const data = rs.payload.doc.data() as Franchise;
        data.id = rs.payload.doc.id;
        return data;
      })
    }),
    map(rs => {
      if (rs.length > 0) {
        return rs[0];
      } else {
        return null;
      }
    })
    )
  }

  getLatLngFromAddress(address: string) {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${environment.apiKeyGoogleMap}`)
      .pipe(throttleTime(1500))
      .toPromise()
      .then(res => res)
      .catch(error => error)
  }

  async addFranchise(franchise: Franchise) {
    try {
      if (franchise.hq_address) {
        const address = franchise.hq_address.replace(/[#]/g, "");
        const result_address = await this.getLatLngFromAddress(address);
        if (result_address.results.length > 0) {
          franchise.hq_address = result_address.results[0].formatted_address;
          franchise.hd_lat = result_address.results[0].geometry.location.lat;
          franchise.hd_long = result_address.results[0].geometry.location.lng;
        }
      }

      return this.afs.collection(`franchise`).add({ ...franchise, created_at: firebase.Timestamp.now() }).then(rs => {
        this.nzMesageSV.success('Franchise successfully added!');
        return ({ flag: true, message: 'Franchise successfully added!', data: { ...franchise, id: rs.id } });
      }).catch((err: any) => {
        return ({ flag: false, message: err.message, data: {} as Franchise });
      });
    } catch (err: any) {
      return ({ flag: false, message: err.message, data: {} as Franchise });
    }
  }

  async updateFranchise(franchise: Franchise) {
    try {
      if (franchise.hq_address) {
        const address = franchise.hq_address.replace(/[#]/g, "");
        const result_address = await this.getLatLngFromAddress(address);
        if (result_address.results.length > 0) {
          franchise.hq_address = result_address.results[0].formatted_address;
          franchise.hd_lat = result_address.results[0].geometry.location.lat;
          franchise.hd_long = result_address.results[0].geometry.location.lng;
        }
      }
      return this.afs.doc(`franchise/${franchise.id}`).update({ ...franchise, updated_at: firebase.Timestamp.now()}).then(() => {
        this.nzMesageSV.success('Franchise successfully updated!');
        return ({ flag: true, message: 'Franchise successfully updated!' });
      }).catch((err: any) => {
        console.log(err);
        return ({ flag: false, message: err.message });
      });
    } catch (err: any) {
      return ({ flag: false, message: err.message });
    }
  }

  deleteFranchise(franchise_id: string) {
    return this.afs.doc(`franchise/${franchise_id}`).delete().then(() => {
      this.nzMesageSV.success('Franchise successfully deleted!');
      return ({ flag: true, message: 'Franchise successfully deleted!' });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message });
    });
  }
  async existsFranchiseBySlug(slug: string) {
    const location = await this.afs.firestore.collection('franchise').where('slug', '==', slug).limit(1).get();
    if (location.size > 0) return true;
    else return false;
  }
}
