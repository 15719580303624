import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Params } from '@angular/router';
import { environment } from '@franbase/shared/environments';
import * as firebase from 'firebase/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, catchError, of, retry, switchMap, throwError } from 'rxjs';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Industry } from '../models/industry.model';

@Injectable({
  providedIn: 'root'
})
export class IndustryService {
  private api_url = environment.api_algolia;
  private http = inject(HttpClient);
  private readonly afs = inject(AngularFirestore);
  private readonly afAuth = inject(AngularFireAuth);
  private readonly nzMesageSV = inject(NzMessageService);
  searchIndustryAlgolia(params: Params) {
    return this.afAuth.idToken.pipe(
      switchMap(res => {
        if (res) {
          console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${res}`
            })
          }
          console.log(params);
          return this.http.post(`${this.api_url}/filter-industry`, params, httpOptions)
            .pipe(
              retry(3), // retry a failed request up to 3 times
              catchError(err=>{
                console.log(err);
                return throwError(() => new Error('API bad happened; please try again later.'));
              })
            )
        } else {
          return of(null);
        }
      }))

  }
  async listIndustry(
    limit = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string,
    order_by?: string,
    order_desc = true) {
    try {
      let query: Query = this.afs.firestore.collection('industry');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }
      if (order_by) {
        query = query.orderBy(order_by, order_desc ? 'desc' : 'asc');
      } else {
        query = query.orderBy('created_at', order_desc ? 'desc' : 'asc');
      }

      if (start_after) {
        const doc = await this.afs.firestore.doc(`industry/${start_after}`).get();
        query = query.startAfter(doc);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`industry/${end_before}`).get();
        query = query.endBefore(doc);
        if (limit != -1) query = query.limit(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query.get().then((querySnapshot: any) => {
        const list: Array<Industry> = [];
        querySnapshot.forEach((doc: any) => {
          const data = doc.data() as Industry;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  listIndustryRealTime(
    limit = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>,
    order_by?: string,
    order_desc = true) {
    try {
      let query: Query = this.afs.firestore.collection('industry');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      if (order_by) {
        query = query.orderBy(order_by, order_desc ? 'desc' : 'asc');
      } else {
        query = query.orderBy('created_at', order_desc ? 'desc' : 'asc');
      }

      if (start_after) {
        query = query.startAfter(start_after);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        query = query.endBefore(end_before);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return new Observable<Industry[]>(observ => {
        query.onSnapshot((querySnapshot: any) => {
          const list: Array<Industry> = [];
          querySnapshot.forEach((doc: any) => {
            const data = doc.data() as Industry;
            data.id = doc.id;
            data.doc = doc;
            list.push(data);
          });
          return observ.next(list);
        })
      });
    } catch (error) {
      console.log(error);
      return of([]);
    }
  }

  getIndustryDetail(industry_id: string) {
    return this.afs.firestore.doc(`industry/${industry_id}`).get().then(res => ({ id: res.id, ...res.data() } as Industry))
  }

  addIndustry(industry: Industry) {
    return this.afs.collection(`industry`).add({ ...industry, created_at: firebase.Timestamp.now() }).then(rs => {
      this.nzMesageSV.success('Industry successfully added!');
      return ({ flag: true, message: 'Industry successfully added!', data: { ...industry, id: rs.id } });
    }).catch((err: any) => {
      this.nzMesageSV.error(err.message);
      return ({ flag: false, message: err.message, data: {} as Industry });
    });
  }

  updateIndustry(industry: Industry) {
    return this.afs.doc(`industry/${industry.id}`).update({ ...industry, updated_at: firebase.Timestamp.now() }).then(() => {
      this.nzMesageSV.success('Industry successfully updated!');
      return ({ flag: true, message: 'Industry successfully updated!' });
    }).catch((err: any) => {
      console.log(err);
      this.nzMesageSV.error(err.message);
      return ({ flag: false, message: err.message });
    });
  }

  deleteIndustry(industry_id: string) {
    return this.afs.doc(`industry/${industry_id}`).delete().then(() => {
      this.nzMesageSV.success('Industry successfully deleted!');
      return ({ flag: true, message: 'Industry successfully deleted!' });
    }).catch((err: any) => {
      this.nzMesageSV.error(err.message);
      return ({ flag: false, message: err.message });
    });
  }

  async existsIndustryBySlug(slug: string) {
    const industry = await this.afs.firestore.collection('industry').where('slug', '==', slug).limit(1).get();
    if (industry.size > 0) return true;
    else return false;
  }

}
