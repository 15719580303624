// ==================================================
// backend: https://franbase-8924f.web.app (gcs)
// frontend: https://frontend-2dm7x4stua-uc.a.run.app (gcr- stopped on Feb 19, 2024)
// frontend: https://frontend-franbase-8924f.web.app (gcs)
// demo: https://demosearch-franbase-8924f.web.app (gcs)
// ==================================================

export const environment = {
  api_algolia: 'https://api.franbase.co/franbase-api-algolia', //(gke)
  api_upload_file: 'https://api.franbase.co/franbase-api-upload-file',//(gke)
  api_franchise: 'https://api.franbase.co/franbase-api-franchise', //(gke)
  api_post: 'https://api.franbase.co/franbase-api-post', //(gke)
  api_user: 'https://api.franbase.co/franbase-api-user', //(gke)
  storage: 'https://storage.franbase.co', //(r53 + cloudfront + s3, cdn-amazon)
  firebase: {
    apiKey: "AIzaSyAcaphjAqrAqgNVzlsaa5Y0a3gmuwYAHAk",
    authDomain: "franbase-8924f.firebaseapp.com",
    projectId: "franbase-8924f",
    storageBucket: "franbase-8924f.appspot.com",
    messagingSenderId: "1005636959544",
    appId: "1:1005636959544:web:ee81fd8cdf0e6db04f55cd",
    measurementId: "G-2XMVJ9SK93"
  },
  apiKeyGoogleMap: "AIzaSyAcaphjAqrAqgNVzlsaa5Y0a3gmuwYAHAk"
}