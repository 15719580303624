import { Injectable, inject } from '@angular/core';
import { InvestmentStoreService } from '@franbase/backend/investment/data-access';
import { LocationStoreService } from '@franbase/backend/location/data-access';
import { AuthStore } from '@franbase/shared/auth/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from "rxjs";
import { AppInit } from './app-init.action';
@Injectable()
export class ApplicationEffects {

  actions$ = inject(Actions);
  LocationStoreService = inject(LocationStoreService);
  InvestmentStoreService = inject(InvestmentStoreService);
  authStore = inject(AuthStore);
  initAuth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppInit),
        switchMap(() => {
          this.authStore.init2();
          return this.authStore.idToken$.pipe(
            tap(rs => {
              if (rs) {
                this.LocationStoreService.loadLocation$();
                this.InvestmentStoreService.loadInvestment$();
              }
            })
          );
        }),
      ),
    {
      dispatch: false
    }
  );
}
