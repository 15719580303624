import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '@franbase/backend/shared/ui/header';
import { FooterComponent } from '@franbase/backend/shared/ui/footer';
import { RouterModule } from '@angular/router';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { ImportExcelFileComponent } from '@franbase/backend/franchise/ui/import-excel-file';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SvgIconComponent } from '@ngneat/svg-icon';

interface NavItem {
  title: string;
  link: string;
  icon: string;
  size: string;
  items?: NavItem[];
}
@Component({
  selector: 'franbase-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderComponent,
    FooterComponent,
    NzMenuModule,
    ImportExcelFileComponent,
    NzLayoutModule,
    NzToolTipModule,
    SvgIconComponent
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {

  siderCollapsed = true;

  items: NavItem[] = [
    {
      title: 'Franchise',
      link: '/franchise',
      icon: 'projectIcon',
      size: '20px',
    },
    {
      title: 'Industry',
      link: '/industry',
      icon: 'industryIcon',
      size: '18px',
    },
    {
      title: 'Location',
      link: '/location',
      icon: 'locationIcon',
      size: '22px',
    },
    {
      title: 'Investment',
      link: '/investment',
      icon: 'investmentIcon',
      size: '20px',
    },
    {
      title: 'Post',
      link: '/post',
      icon: 'bookIcon',
      size: '20px',
    },
    {
      title: 'Post Category',
      link: '/post-category',
      icon: 'bookmarkIcon',
      size: '22px',
    },
    {
      title: 'Media',
      link: '/media',
      icon: 'uploadIcon',
      size: '24px',
    }
  ];

  ngOnInit() {
    const value = localStorage.getItem('siderCollapsed');
    this.siderCollapsed =
      value === null ? true : value === 'false' ? false : true;
  }

  updateSiderStorage() {
    this.siderCollapsed = !this.siderCollapsed;
    localStorage.setItem('siderCollapsed', String(this.siderCollapsed));
  }
}
