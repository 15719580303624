/* eslint-disable */
import { User } from '@franbase/shared/data-access';
export interface AuthState {
    auth_profile: User | null,
    idToken: string | null,
}

export const inititialState: AuthState = {
    auth_profile: null,
    idToken: null
};