/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable, inject } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map } from 'rxjs';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Post } from '../models/post.model';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  private readonly afs = inject(AngularFirestore);
  private readonly nzMesageSV = inject(NzMessageService);

  getPostDetail(post_id: string) {
    return this.afs.doc<Post>(`post/${post_id}`).valueChanges({ idField: 'id' });
  }

  async listPost(limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string) {
    try {
      let query: Query = this.afs.firestore.collection('post');
      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }
      query = query.orderBy('created_at', 'desc');
      if (start_after) {
        const doc = await this.afs.firestore.doc(`post/${start_after}`).get();
        query = query.startAfter(doc).limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`post/${end_before}`).get();
        query = query.endBefore(doc).limitToLast(limit);
      } else {
        query = query.limit(limit);
      }
      return query.get().then(querySnapshot => {
        const list: Array<Post> = [];
        querySnapshot.forEach((doc: any) => {
          const data = doc.data() as Post;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      }).catch(error => {
        console.log(error);
        return [];
      });
    } catch (error) {
      console.log("ERROR-list-post", error);
      return [];
    }
  }
  addPost(post: Post) {
    return this.afs.collection(`post`).add({ ...post }).then((rs: any) => {
      this.nzMesageSV.success('Post successfully added!');
      return ({ flag: true, message: 'Post successfully added!', data: { ...post, id: rs.id } });
    }).catch((error: any) => {
      this.nzMesageSV.success(error.message);
      return ({ flag: false, message: error.message, data: {} as Post });
    });
  }

  updatePost(post: Post) {
    delete post.doc;
    return this.afs.doc(`post/${post.id}`).update({ ...post }).then(() => {
      this.nzMesageSV.success('Post successfully updated!');
      return ({ flag: true, message: 'Post successfully updated!' });
    }).catch((error: any) => {
      this.nzMesageSV.success(error.message);
      return ({ flag: false, message: error.message });
    });
  }

  deletePost(post_id: string) {
    return this.afs.doc(`post/${post_id}`).delete().then(() => {
      this.nzMesageSV.success('Post successfully deleted!');
      return ({ flag: true, message: 'Post successfully deleted!' });
    }).catch((error: any) => {
      this.nzMesageSV.success(error.message);
      return ({ flag: false, message: error.message });
    });
  }

  getPostDetailbySlug(slug: string) {
    return this.afs.collection('post', ref => {
      return ref.where('slug', '==', slug)
    }).snapshotChanges().pipe(map(actions => {
      return actions.map(rs => {
        console.log(rs);
        let data = rs.payload.doc.data() as Post;
        data.id = rs.payload.doc.id;
        return data;
      })
    }),
      map(rs => {
        if (rs.length > 0) {
          return rs[0];
        } else {
          return null;
        }
      })
    )
  }
  
  async existsPostbySlug(slug: string) {
    const post = await this.afs.firestore.collection('post').where('slug', '==', slug).limit(1).get();
    if (post.size > 0) return true;
    else return false;
  }
}
