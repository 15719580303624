/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { environment } from '@franbase/shared/environments';
import { of, switchMap, take } from 'rxjs';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Media } from '../models/media.model';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  private afs = inject(AngularFirestore);
  http = inject(HttpClient);
  afAuth = inject(AngularFireAuth);

  // getMediaDetail(media_id: string) {
  //   return this.afs.doc<Media>(`media/${media_id}`).valueChanges({ idField: 'id' });
  // }
  getMediaDetail(media_id: string) {
    return this.afs.firestore.doc(`media/${media_id}`).get().then(res => ({ id: res.id, ...res.data() } as Media))
  }

  async listMedia(limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string) {
    try {
      let query: Query = this.afs.firestore.collection('media');
      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }
      query = query.orderBy('created_at', 'desc');
      if (start_after) {
        const doc = await this.afs.firestore.doc(`media/${start_after}`).get();
        query = query.startAfter(doc).limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`media/${end_before}`).get();
        query = query.endBefore(doc).limitToLast(limit);
      } else {
        query = query.limit(limit);
      }
      return query.get().then(querySnapshot => {
        const list: Array<Media> = [];
        querySnapshot.forEach((doc: any) => {
          const data = doc.data() as Media;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      }).catch(error => {
        console.log(error);
        return [];
      });
    } catch (error) {
      console.log("ERROR-list-media", error);
      return [];
    }
  }

  addMedia(media: Media) {
    return this.afs.collection(`media`).add({ ...media }).then((rs: any) => {
      return ({ flag: true, message: 'Media successfully added!', data: { ...media, id: rs.id } });
    }).catch((error: any) => {
      return ({ flag: false, message: error.message, data: {} as Media });
    });
  }

  updateMedia(media: Media) {
    delete media.doc;
    return this.afs.doc(`media/${media.id}`).update({ ...media }).then(() => {
      return ({ flag: true, message: 'Media successfully updated!' });
    }).catch((error: any) => {
      return ({ flag: false, message: error.message });
    });
  }

  deleteMedia(media_id: string) {
    return this.afs.doc(`media/${media_id}`).delete().then(() => {
      return ({ flag: true, message: 'Media successfully deleted!' });
    }).catch((error: any) => {
      return ({ flag: false, message: error.message });
    });
  }

  getPresignedUrlUploadFileS3(file_name: string, file_size: number) {
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          console.log(token);
          const data = {
            'file_name': file_name,
            'file_size': file_size
          }
          try {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              })
            }
            return this.http.post(`${environment.api_upload_file}/get-link-upload`, data, httpOptions)
              .toPromise()
              .then(res => res)
              .catch(error => error);
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          return of(null);
        }
      })).pipe(take(1)).toPromise();
  }

  deleteFileUploadS3(file_name: string) {
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          const data = {
            'file_name': file_name
          }
          try {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              })
            }
            return this.http.post(`${environment.api_upload_file}/delete-file`, data, httpOptions)
              .toPromise()
              .then(res => res)
              .catch(error => error);
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          return of(null);
        }
      })).pipe(take(1)).toPromise();
  }
}
