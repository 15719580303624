import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { Location, LocationService } from '@franbase/shared/data-access';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable, catchError, combineLatest, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

interface LocationState extends EntityState<any> {
  loading: boolean,
  error: string
}
const adapter = createEntityAdapter<any>();
const initialState: LocationState = adapter.getInitialState({
  loading: false,
  error: ''
})

@Injectable({
  providedIn: 'root'
})
export class LocationStoreService extends ComponentStore<LocationState> {

  LocationSV = inject(LocationService);

  constructor() { super(initialState); }

  data$ = this.select(s=> Object.values(s.entities) as Location[]);
  loading$ = this.select((s) => s.loading);
  error$ = this.select((s) => s.error);

  loadLocation$ = this.effect(none$ => {
    return none$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap(() => {
        return this.LocationSV.listLocation(-1, [], undefined, undefined, 'title', true);
      }),
      map((final_list: Location[]) => {
        if (final_list && final_list.length > 0) {
          this.setState((state) => adapter.setAll(final_list, state));
          this.patchState({ loading: false });
        }

        if (final_list.length == 0) {
          this.patchState({ loading: false });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  createLocation$ = this.effect((location$: Observable<Location>) => {
    return location$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((location: Location) => {
        return this.LocationSV.addLocation(location);
      }),
      map(rs => {
        if (rs.flag) {
          this.setState((state) => adapter.setOne(rs.data, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  updateLocation$ = this.effect((location$: Observable<Location>) => {
    return location$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((location: Location) => {
        return combineLatest(this.LocationSV.updateLocation(location), of(location));
      }),
      map(([rs, location]) => {
        if (rs.flag) {
          this.setState((state) => adapter.updateOne({id: location.id!, changes: location}, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  deleteLocation$ = this.effect((location_id$: Observable<string>) => {
    return location_id$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((location_id: string) => {
        return combineLatest(this.LocationSV.deleteLocation(location_id), of(location_id));
      }),
      map(([rs, location_id]) => {
        if (rs.flag) {
          this.setState((state) => adapter.removeOne(location_id, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  getOneLocation(location_id: string) {
    return this.select((s) => s.entities[location_id] as Location);
  }
  existsLocationBySlug(slug: string) {
    return this.LocationSV.existsLocationBySlug(slug);
  }
}
