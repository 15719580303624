import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@franbase/shared/environments';
import { throttleTime } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  http = inject(HttpClient);

  api_franchise = environment.api_franchise;
  api_post = environment.api_post;
  api_agolia = environment.api_algolia;
  api_user = environment.api_user;
  storage = environment.storage;

  getAllCategories() {
    return this.http.get(this.storage + "/categories.json");
  }

  listFranchiseByCategory(data = {}) {
    return this.http.post(this.api_agolia + '/list-franchise-by-cate', data, httpOptions)
      .pipe(throttleTime(1500))
      .toPromise().then(rs => rs).catch(error => error);
  }

  franchiseDetail(get_by: "slug" | "id", value: string) {
    const data = {
      get_by: get_by,
      value: value
    }
    return this.http.post(this.api_franchise + '/franchise-detail', data, httpOptions)
      .pipe(throttleTime(1500))
      .toPromise().then(rs => rs).catch(error => error);
  }

  franchiseCategoryDetail(get_by: "slug" | "id", value: string, type: "industry" | "investment" | "location") {
    const data = {
      get_by: get_by,
      value: value,
      type: type
    }
    return this.http.post(this.api_franchise + '/franchise-category-detail', data, httpOptions)
      .pipe(throttleTime(1500))
      .toPromise().then(rs => {
        return rs;
      }).catch(error => error);
  }

  listPostByCategory(data = {}) {
    return this.http.post(this.api_agolia + '/list-post-by-cate', data, httpOptions)
      .pipe(throttleTime(1500))
      .toPromise().then(rs => rs).catch(error => error);
  }

  postDetail(get_by: "slug" | "id", value: string) {
    const data = {
      get_by: get_by,
      value: value
    }
    return this.http.post(this.api_post + '/post-detail', data, httpOptions)
      .pipe(throttleTime(1500))
      .toPromise().then(rs => rs).catch(error => error);
  }

  pageDetail(get_by: "slug" | "id", value: string) {
    const data = {
      get_by: get_by,
      value: value
    }
    return this.http.post(this.api_post + '/page-detail', data, httpOptions)
      .pipe(throttleTime(1500))
      .toPromise().then(rs => rs).catch(error => error);
  }

  listPostCategory(data = {}) {
    return this.http.post(this.api_post + '/list-category-post', data, httpOptions)
      .pipe(throttleTime(1500))
      .toPromise().then(rs => rs).catch(error => error);
  }

  postCategoryDetail(get_by: "slug" | "id", value: string) {
    const data = {
      get_by: get_by,
      value: value
    }
    return this.http.post(this.api_post + '/post-cate-detail', data, httpOptions)
      .pipe(throttleTime(1500))
      .toPromise().then(rs => rs).catch(error => error);
  }

  signup(email: string, password: string, first_name: string, last_name: string) {
    const data = {
      email: email,
      password: password,
      first_name: first_name,
      last_name: last_name
    }
    return this.http.post(this.api_user + '/signup', data, httpOptions)
      .pipe(throttleTime(1500))
      .toPromise().then(rs => rs).catch(error => error);
  }
}
