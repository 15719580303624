import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserDropdownComponent } from '@franbase/backend/shared/ui/user-dropdown';
@Component({
  selector: 'franbase-header',
  standalone: true,
  imports: [CommonModule, RouterModule, UserDropdownComponent],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {}
