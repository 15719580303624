/* eslint-disable */
import { inject, Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AuthService, User, UserService } from '@franbase/shared/data-access';
import { ComponentStore } from "@ngrx/component-store";
import { catchError, combineLatest, EMPTY, map, switchMap } from "rxjs";

import { AuthState, inititialState } from "./auth.state";

@Injectable({ providedIn: 'root' })
export class AuthStore extends ComponentStore<AuthState> {
    authSV = inject(AuthService);
    userSV = inject(UserService)
    afAuth = inject(AngularFireAuth);
    constructor(
    ) {
        super(inititialState);
    }
    readonly auth_id$ = this.select(state => state.auth_profile?.uid);
    readonly authProfile$ = this.select(state => state.auth_profile);
    readonly idToken$ = this.select(state => state.idToken);
    readonly data$ = this.select(s => s);

    readonly init2 = this.effect(() => {
        return this.afAuth.authState.pipe(
            switchMap((auth) => {
                if (auth) {
                    return this.afAuth.idToken.pipe(
                        switchMap((idToken) => {
                            this.patchState({ idToken: idToken });
                            return this.userSV.getUserDetail(auth.uid);
                        }),
                        map(userInfo => {
                            if (userInfo) userInfo.uid = auth.uid;
                            this.patchState({ auth_profile: userInfo });
                        })
                    )
                }
                this.patchState({ auth_profile: null });
                return EMPTY
            }), catchError(() => {
                this.patchState({ auth_profile: null });
                return EMPTY
            })
        )
    })
}
