<nz-layout class="main-layout h-screen w-screen bg-white">
  <nz-header
    class="main-header fixed top-0 w-full shadow z-20 px-5 bg-white h-auto"
  >
    <franbase-header></franbase-header>
  </nz-header>
  <nz-layout
    class="second-layout fixed top-[56px] left-0 right-0 bottom-0 z-10 bg-white"
  >
    <nz-sider
      nzCollapsible
      [nzCollapsed]="siderCollapsed"
      [nzWidth]="siderCollapsed?'70px':'220px'"
      nzTheme="light"
      [nzTrigger]="null"
      class="main-sider fixed left-0 z-10 overflow-y-auto overflow-x-hidden h-[calc(100%-104px)] pt-3 border-r border-gray-200 shadow-md"
    >
      <ul
        nz-menu
        nzMode="inline"
        *ngIf="items; else noItems"
        class="main-sider-menu border-0 [&.ant-menu-inline-collapsed>li]:!bg-transparent [&.ant-menu-inline-collapsed>li>.ant-menu-submenu-title]:!p-0 [&.ant-menu-inline-collapsed>li>.ant-menu-submenu-title]:justify-center [&.ant-menu-inline-collapsed>li>.ant-menu-submenu-title>div]:p-2.5 [&.ant-menu-inline-collapsed>li>.ant-menu-submenu-title>div]:bg-gray-100 [&.ant-menu-inline-collapsed>li>.ant-menu-submenu-title>div]:rounded [&.ant-menu-inline-collapsed>.ant-menu-item-selected>.ant-menu-submenu-title>div]:bg-primary-500"
      >
        <ng-container *ngFor="let item of items; index as i">
          <li
            nz-submenu
            [routerLink]="item.link"
            routerLinkActive="ant-menu-item-selected"
            [routerLinkActiveOptions]="{exact: false}"
            [nzTitle]="title"
            class="[&>.ant-menu-submenu-title]:bg-transparent [&>.ant-menu-submenu-title]:transition-none transition-none group [&.ant-menu-item-selected>.ant-menu-submenu-title>div>.title]:text-white [&.ant-menu-item-selected>.ant-menu-submenu-title>div>svg-icon]:text-white [&.ant-menu-item-selected]:!bg-primary-500 hover:!bg-primary-500 [&>.ant-menu-submenu-title>div>.title]:hover:text-white [&>.ant-menu-submenu-title>div>svg-icon]:hover:text-white"
            [nzTooltipTitle]="item.title" nzTooltipPlacement="topRight" nz-tooltip
          >
          <ng-template #nochild></ng-template>
          <ng-template #title>
            <!-- <span class="effect-item"> </span> -->
            <div class="flex items-center space-x-3 relative z-20">
              <svg-icon [key]="item.icon" [fontSize]="item.size" class=" text-gray-600 group-hover:text-primary-500"></svg-icon>
              <span class="title text-gray-600 group-hover:text-primary-500 font-bold">{{ item.title }}</span>
            </div>
          </ng-template>
          </li>
        </ng-container>
      </ul>
      <ng-template #noItems>Empty list</ng-template>
      <div
        class="cursor-pointer flex justify-center items-center fixed left-0 right-0 bottom-0 z-20 h-12 border-t border-r border-gray-200 hover:bg-primary-50 bg-white {{
          siderCollapsed ? 'w-20' : 'w-[220px]'
        }}"
        (click)="updateSiderStorage()"
      >
      <svg-icon
        [key]="siderCollapsed ? 'arrowCircleRightIcon' : 'arrowCircleLeftIcon'"
        fontSize="24px"
        class="text-gray-400 group-hover:text-primary-500"
      ></svg-icon>
      </div>
    </nz-sider>

    <nz-layout
      class="inner-layout {{
        siderCollapsed ? 'ml-20' : 'ml-[220px]'
      }} bg-white relative"
    >
      <router-outlet></router-outlet>
    </nz-layout>
  </nz-layout>
</nz-layout>

